@import '../../../assets/scss/colors.scss';

.common_radio_button_wrapper {
  label {
    color: $white;
  }

  .custom_radio_button {
    .MuiIconButton-label {
      div {
        svg {
          color: $sub-text;
        }
      }
    }

    &.Mui-checked {
      .MuiIconButton-label {
        div {
          svg {
            color: $prim-ui;
          }
        }
      }

      &.Mui-checked + span {
        color: $white;
      }
    }
  }
}

.my_form_contol_label {
  span {
    color: $sub-text;
    font-family: $default-font;
    font-size: 15px;
    font-style: normal;
    letter-spacing: -0.32px;
  }
}

.custom_radio_group {
  padding: 10px 0;
  gap: 6px 15px;
  .MuiFormControlLabel-root {
    gap: 6px;
    margin: 0;
    .MuiButtonBase-root {
      padding: 0;
    }
  }
}
